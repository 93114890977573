import axios from 'axios';
import { LOCAL_DEBUG, METRIC_EARNINGS } from 'constants/types';
import { getTimestamps } from 'utility/utils';

let SERVER_URL = "https://musicfuturesapi.hoyack.com";
// let SERVER_URL = "http://localhost:3001"
if (LOCAL_DEBUG) {
  SERVER_URL = "http://localhost:5000";
}

export const formatNumberWithCommas = (number) => {
  let numberString = number.toString();
  let isNegativeValue = numberString.startsWith("-") ? true : false
  let unSignedValueofNumberString = isNegativeValue ? numberString.split('-')[1] : numberString
  // Split the number into integer and decimal parts
  let parts = unSignedValueofNumberString.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Add commas to the integer part
  let formattedIntegerPart = '';
  for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
    if (j > 0 && j % 3 === 0) {
      formattedIntegerPart = ',' + formattedIntegerPart;
    }
    formattedIntegerPart = integerPart[i] + formattedIntegerPart;
  }

  // Combine integer and decimal parts
  let formattedNumber = formattedIntegerPart + decimalPart;

  return isNegativeValue ? "-" + formattedNumber : formattedNumber;
}

export const calculateAllTimeStreams = (tracks) => {
  const trackMap = new Map();

  tracks.forEach(trackObj => {
    const track = trackObj.wr_track;
    const trackName = track.name;
    const trackPlaycount = track.playcount

    if (trackMap.has(trackName)) {
      const existingData = trackMap.get(trackName);
      existingData.playcounts.push(trackPlaycount);
    } else {
      trackMap.set(trackName, { playcounts: [trackPlaycount] });
    }

  });

  let totalPlaycount = 0;
  trackMap.forEach(data => {
    const averagePlaycount = data.playcounts.reduce((acc, count) => acc + count, 0) / data.playcounts.length;
    totalPlaycount += averagePlaycount;
  });

  return formatNumberWithCommas(Math.floor(totalPlaycount));
}

function calculateTotalUniquePlaycount(tracks) {
  const trackMap = new Map();

  tracks.forEach(trackObj => {
    const track = trackObj.wr_track;
    const trackName = track.name;
    const wrTrackTs = track.wr_track_ts;

    if (wrTrackTs && wrTrackTs.length > 0) {
      const lastIndexPlaycount = wrTrackTs[wrTrackTs.length - 1].playcount;

      if (trackMap.has(trackName)) {
        const existingData = trackMap.get(trackName);
        existingData.playcounts.push(lastIndexPlaycount);
      } else {
        trackMap.set(trackName, { playcounts: [lastIndexPlaycount] });
      }
    }
  });

  let totalPlaycount = 0;
  trackMap.forEach(data => {
    const averagePlaycount = data.playcounts.reduce((acc, count) => acc + count, 0) / data.playcounts.length;
    totalPlaycount += averagePlaycount;
  });

  return Math.floor(totalPlaycount);
}


export const calculateCatalogEarnings = (allTimeStreams) => {
  // Remove commas from the string
  // console.log("allTimeStreams :", allTimeStreams)
  let streamCount = Math.floor(allTimeStreams);

  let catalogEarnings = Math.floor(streamCount * METRIC_EARNINGS);

  return formatNumberWithCommas(catalogEarnings);
}


export const getArtistCount = async () => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/count`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
}


export const getArtists = async (filter = "", pagesize = 20, offset = 0) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/?filter=${filter}&pagesize=${pagesize}&offset=${offset}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistsByName = async (name) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/search/?name=${name}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistById = async (id) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

export const getArtistBySlug = async (slug) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/slug/${slug}`;
    const response = await axios.get(url);
    let artist = response.data[0];
    return artist;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

export const getArtistStreamCountsByWeek = async (id, lastDayOfWeek, lastDayOfPreviousWeek) => {
  try {
    const urlthisWeek = `${SERVER_URL}/api/v1/artist/ts/${id}/?before=${lastDayOfWeek}`
    const thisWeekResp = await axios.get(urlthisWeek);
    const thisWeekTracks = thisWeekResp.data[0].wr_artist_tracks;

    const urlPreviousWeek = `${SERVER_URL}/api/v1/artist/ts/${id}/?before=${lastDayOfPreviousWeek}`
    const previousWeekResp = await axios.get(urlPreviousWeek);
    const previousWeekTracks = previousWeekResp.data[0].wr_artist_tracks;

    const playCountSumUntilThisWeek = calculateTotalUniquePlaycount(thisWeekTracks);
    const playCountSumUntilPreviousWeek = calculateTotalUniquePlaycount(previousWeekTracks)

    return playCountSumUntilThisWeek - playCountSumUntilPreviousWeek;
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}

export const getArtistWeeklyStreamChange = async (id) => {
  const timestamps = getTimestamps(Date.now());

  const currentWeeklyStreams = await getArtistStreamCountsByWeek(id, timestamps[1], timestamps[3]);
  console.log("Current Weekly Streams: ", currentWeeklyStreams);
  const lastWeeksWeeklyStreams = await getArtistStreamCountsByWeek(id, timestamps[3], timestamps[5])
  console.log("Last Weeks Weekly Streams: ", lastWeeksWeeklyStreams);

  return parseInt(currentWeeklyStreams) - parseInt(lastWeeksWeeklyStreams);
}

export const getArtistRankingByFollowers = async (followers) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/ranking_f/?followers=${followers}`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistRankingByTopsong = async (playcount) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/ranking_t/?playcount=${playcount}`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getArtistRankingByCountry = async (country, listeners) => {
  try {
    const url = `${SERVER_URL}/api/v1/artist/ranking_c/?country=${country}&listeners=${listeners}`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};
